import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { faMusic } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { SearchResult } from 'hooks/useSearch'
import { useAppDispatch } from 'store'
import { resetSearch } from 'store/modules/search'

interface SearchResultRowProps {
    result: SearchResult
    active: boolean
    onHover: () => void
}

function SearchResultRow({ result, active, onHover }: SearchResultRowProps) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const onClick = () => {
        dispatch(resetSearch())
        navigate(`/song/${result.UID}`)
    }

    return (
        <Result active={active} onClick={onClick} onMouseEnter={onHover}>
            <Icon icon={faMusic} />
            <span>{result.title}</span>
        </Result>
    )
}

const Icon = styled(FontAwesomeIcon)`
    position: relative;
    font-size: 1.25rem;
    color: rgb(60, 60, 60);
    left: -16px;
`

interface ResultProps {
    active: boolean
}

const Result = styled.div<ResultProps>`
    cursor: pointer;
    padding: 4px 32px 4px 32px;
    font-size: 1.125rem;
    background-color: ${props => (props.active ? '#E6E6E6' : 'white')};

    &:hover {
        background-color: #E6E6E6;
    }
`

export default SearchResultRow
