import React from 'react'
import styled from 'styled-components'

import Table from 'components/Table'
import { TABLE_TITLES, BOOK_REFS_MAP } from 'utils/constants'
import { SongType } from 'types'

interface ReferencesTableProps {
    song: SongType
}

function getReferenceRows(song: SongType) {
    return Object.keys(BOOK_REFS_MAP)
        .filter(key => song[key as keyof SongType])
        .map(key => {
            const data = song[key as keyof SongType] as string

            return { label: BOOK_REFS_MAP[key], data }
        })
}

function ReferencesTable({ song }: ReferencesTableProps) {
    const rows = getReferenceRows(song)

    return <Wrapper><Table title={TABLE_TITLES.references} rows={rows} /></Wrapper>
}

const Wrapper = styled.div`
    padding: 32px 0 32px 40px;
    border-color: ${props => props.theme.color.purple};
`

export default ReferencesTable
