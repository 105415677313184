import React from 'react'
import styled from 'styled-components'

interface LongInfoFieldProps {
    label: string
    data: string | undefined
}

function Lines({ data }: { data: string }) {
    const dataLines = data.split('\n')

    return (
        <div>
            {dataLines.map(line => {
                return (
                    <React.Fragment key={line}>
                        <span>{line}</span>
                        <br />
                    </React.Fragment>
                )
            })}
        </div>
    )
}

function LongInfoField({ label, data }: LongInfoFieldProps) {
    return (
        <>
            {data && (
                <Wrapper>
                    <Label>{label}</Label>
                    <Lines data={data} />
                </Wrapper>
            )}
        </>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1.125rem;
    font-weight: 300;
    padding: 8px 16px 8px 0;
`

const Label = styled.span`
    font-weight: 400;
    color: ${props => props.theme.color.purple};
    padding-bottom: 4px;
`

export default LongInfoField
