import React from 'react'
import styled from 'styled-components'

import Table from 'components/Table'
import { SONG_LABELS_MAP, TABLE_TITLES } from 'utils/constants'
import { SongType } from 'types'

interface OriginTableProps {
    song: SongType
}

function OriginTable({ song }: OriginTableProps) {
    const rows = [
        { label: SONG_LABELS_MAP.melodyOrigin, data: song.melodyOrigin || '-' },
        { label: SONG_LABELS_MAP.textOrigin, data: song.textOrigin || '-' },
        {
            label: SONG_LABELS_MAP.translationTextOrigin,
            data: song.translationTextOrigin || '-',
        },
    ]

    return <Wrapper><Table title={TABLE_TITLES.origin} rows={rows} /></Wrapper>
}

const Wrapper = styled.div`
    padding: 32px 0 32px 40px;
    border-color: ${props => props.theme.color.purple};

    @media (max-width: 640px) {
        border-bottom: 1px solid;
        border-color: ${props => props.theme.color.purple};
    }
`

export default OriginTable
