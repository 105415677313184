const TITLE = 'Tere tulemast eestikeelse kirikulaulu andmebaasi'

const INTRO_P1 =
    'Kirikulaulu rolli meie muusikakultuuris on võimatu üle hinnata. Kas vaatame tuhande aasta vanust kirjalikku muusikakultuuri või meie oma Eesti laulupidu – kõikide määrava tähtsusega nähtuste kujunemise epitsentris on just kirikulaul. See tähendab, et meil lasub kohustus tunda, väärtustada ja hoida kirikulaulu tervikut, eriti aga meie emakeelset kirikulaulu.'
const INTRO_P2 =
    'Tänaseks on eestikeelse kirikulaulu kultuuri lisandunud niivõrd palju kihistusi, et materjalis orienteerumiseks on vaja hästitoimivat võrdlusaparaati. Kirikulaulus kasutatakse nii varasemaid teadaolevaid palasid meie muusikakultuurist, kui verivärsket loomingut tänasest päevast. Paljudel vanematel lauludel on olemas erinevaid variante nii meloodiliselt, rütmiliselt kui tekstiliselt. Seda varandust on vaja talletada elektrooniliselt. Samuti on vaja koguda ja jäädvustada tänapäevast kirikulaulu, et mõista eestikeelse kirikulaulu retseptsiooni kogu selle eksisteerimise ajateljel.'
const INTRO_P3 =
    'Just selleks ongi mitmete inimeste koostöös loodud „Eestikeelse Kirikulaulu Andmebaas“ ehk E–KLAB. Selle eesmärgiks on koondada ühtsesse andmekogusse võimalikult terviklik eestikeelse kirikulaulu repertuaar ja pakkuda kogutud materjal kasutamiseks välja nii kirikulaulu igapäevapraktikutele kui seda valdkonda uurivatele teadlastele ja üliõpilastele.'

const INTRO = [INTRO_P1, INTRO_P2, INTRO_P3]

const COMMUNITY_TITLE = 'Kogukond'

const COMMUNITY_P1 =
    'KLABis on kaks erinevat kasutajaliidest: (1) tavakasutaja ning (2) administraator. Tavakasutaja saab vaadata andmebaasis sisalduvat teavet ja seda kasutada. Administraator saab lisada uusi laule ja teha muutusi olemasolevate laulude kirjetes. Administraatoriks võib saada igaüks, kes soovib kaasa töötada E–KLABi laulude sisestamisel ja olemasoleva teabe toimetamisel. Selleks, et saada administraatoriks tuleb kirjutada Eerik Jõksile aadressil eerik.joks@ema.edu.ee'

const COMMUNITY = [COMMUNITY_P1]

const MAIN_PART_TITLE = 'E–KLABist ja kirikulaulust pisut pikemalt'

const MAIN_PART_P1 =
    'Eestikeelse Kirikulaulu Andmebaasi (E–KLAB) eesmärgiks on luua laialdane ja ülevaatlik akadeemiliselt kõrgetasemeline ning hästikäsitsetava kasutajaliidesega andmekogu, mis võimaldaks (1) talletada digitaalselt meie vanema luterliku kirikulaulu pärandi ja seda interdistsiplinaarselt uurida; (2) koguda uuemat kirikulaulu ja teha seda puudutav teave repertuaari populariseerimise eesmärgil kaasaegselt ja atraktiivselt kättsaadavaks; (3) soodustada kirikulaulu retseptsiooni uurimist kogu eestikeelse kirikulaulu ajatelje ulatuses, alates esimestest allikatest 17. sajandil kuni tänapäevani välja. Muusikateaduse, filoloogia ja teoloogia sümbioosis saab sündida eesti rahvuskultuuri tüvidistsipliinide (1) kirjandusloo, (2) teoloogia ja (3) muusikaloo sügavam mõistmine, mis pakub meie rahvuskultuurile uut elujõudu ja säilimise ning kasvamise perspektiivi.'

const MAIN_PART_P2 =
    'E–KLAB sai alguse 27. augustil 2019 kui Eerik Jõks tegi ettepaneku Tartu Ülikooli Arvutiteaduse Instituudile võtta E–KLAB üliõpilaste tarkvara programmeerimise üheks praktikatööks. Üliõpilaste meeskond koosseisus Magnar Vares, Karl Meldorf, Karl Toomas Vana ja Mihkel Kruusi otsustasidki võtta E–KLABi oma ühesemestriliseks praktikatööks. Üliõpilasi juhendas prof Kuldar Taveter. Rakenduse nii serveri- kui ka kliendipoolne osa on kirjutatud Javascriptis, kasutades vastavalt Node.js ja React.js raamistikke. Andmebaasina on kasutusel MongoDB. Andmebaasil on kaks kasutajaliidest: (1) tavakasutaja ning (2) administraator.'

const MAIN_PART_P3 =
    'Andmebaasi sisuliseks „seemneks“ sai prof Toomas Siitani koostatud andmekogu „Eesti tähtsamate lauluraamatute ja koraaliraamatute tiitlite võrdlev kataloog“, mis valmis enne 2003. aastat ja mida ei ole pärast seda täiendatud. Siitani kataloogis on 1954 kirjet, kokku kaheksast väljaandest vahemikus 1721–1991. Andmeväljad selles kataloogis on: (1) Põhja-Eesti tiitel; (2) Lõuna-Eesti tiitel; (3) saksa (või muu) tiitel; (4) teksti autor; (5) tõlkija; (6–13) viited kaheksale väljaandele; (14) värsimõõt; (15) stroofivalem.'

const MAIN_PART_P4 =
    'Eerik Jõks pakkus 12.11.2019 E–KLABi töövahendiks EELK uue lauluraamatu koostamisel. Komisjon lükkas selle ettepaneku tagasi. E–KLABi esimene etapp sai valmis 2020. aasta alguses. Pärast seda on E–KLABiga mõningast tööd jätkanud Karl Meldorf, kes tegi Eerik Jõksi tellimusel parandusi ja täiendusi. Tööd toetati ka Evangelical Lutheran Church in Northern Germany – Centre for Global Ministries and Ecumenical Relations poolt. E–KLABi arendust on toetanud Eerik Jõks isiklikest vahenditest ja MTÜ Liturgilise Muusika Ühing Scandicus oma eelarvest. Seejärel jäi töö E–KLABiga soiku. Nüüd, 2021. aasta lõpus, on tekkinud uus initsiatiiv minna E–KLABi projektiga edasi. See oleks väga soovitav, sest praegu on meil veel olemas tippspetsialistid, kes suudaksid sellise universaalse andmekogu koostamisega hakkama saada.'

const MAIN_PART_P5 =
    'Koos kiriku rolli vähenemisega meie ühiskonnas, on hakatud kirikulaulu, nii nagu ka kogu kirikumuusika tähendust eesti kultuuri tervikpildis selgelt alahindama, mistõttu on kirikulaul ja kirikumuusika tervikuna, kui professionaalse muusikakutuuri osa, jäänud vaeslapse rolli nii interpretatsioonilises, kui teaduslikus vaates. Eesti formaalselt haridusmaastikult on kirikumuusika süsteemne õpetamine praktiliselt kadunud. Bakalaureusetasemel ei ole Eestis enam võimalik kirikumuusikat õppida. Ühe muusikalise distsipliini õpetamine akadeemilisel kõrgtasemel ei tähenda aga mitte ainult heade teoreetiliste teadmiste ja "käsitööoskustega" spetsialistide koolitamist, vaid ka igale kultuurinähtusele absoluutselt hädavajaliku akadeemilise mõtteruumi säilitamist ja elujõulisuse kasvatamist. Kirikumuusika, sh kirikulaulu akadeemiline mõtteruum Eestis on hääbumas.'

const MAIN_PART_P6 =
    'Ometi on kirikumuusika ja eriti veel kirikuaul midagi sellist, mida me võime täie õigusega nimetada nii Euroopa kui Eesti professionaalse muusikakultuuri keskseks tüvidistsipliiniks. Nii nagu keskaegne sakraalne ladina monoodia (kõnekeeles gregooriuse laul) „sünnitas“ noodikirja, mis tegi võimalikuks polüfoonia edasise kujunemise, niisamuti sillutas saksa kirikulaul teed Eesti rahvuslikule muusika- ja kirjanduskultuurile, sh meie laulupidudele ja lõppriimilisele luulele. Vaadates kirikulaulu rolli meie rahvuskultuuri tekkimisel ja tõdedes selle ainese tänast perspektiivi meie mõtteruumis on tunda tugevat kognitiivset dissonantsi.'

const MAIN_PART_P7 =
    'Eesti kirikumuusikas toimub pragugi mitmesuguseid tegevusi. Jumalateenistustel lauldakse ja mängitakse orelit ning teisi instrumente; toimuvad kontserdid; kirjutatakse kirikumuusika uudisloomingut; koostatakse uut lauluraamatut; esineb ka mõningaid teadustöid selles vallas. Ma olen peaaegu kindel, et ma ei eksi kui väidan, et praegust tegevust hoiab liikumises sajanditega tekkinud traditsiooni inerts ja sellega integraalselt seotud pea pretsedenditu õhinapõhisus. Kui aga see inerts raugeb, siis hoogu saab sellele anda ainult akadeemiliselt kõrgetasemelise mõtteruumi taastekkimine. Inerts jõuab ilmselt madalseisu umbes ühe inimpõlve (20 aastat) jooksul, mil praegu tegutsev ja veel „vanast ajast“ haritud kirikumuusikute põlvkond pensioneerub. Kuna uusi hästi haritud kirikumuusikuid juurde ei koolitata, siis ootab kirikumuusika areaali paratamatu kollaps. Selleks, et midagi sellist ei juhtuks, peaks praegu uus põlvkond kirikumuusikuid alustama oma õpinguid bakalaureusetasemel. See ei ole aga võimalik, sest kirikumuusika haridusformaat on hävinud ja ühiskondlikku tellimust kõrgelt haritud kirikumuusikule enam ei ole. E–KLAB ON OSA POSITIIVSEST PROGRAMMIST, KUIDAS SÄILITADA EESTIS KIRIKUMUUSIKA AKADEEMILISELT KÕRGETASEMELINE MÕTTERUUM JA VÕIMALUSEL ANDA SELLELE KA HOOGU UUEKS FORMALISEERUMISEKS MÕNE KÕRGKOOLI JUURES.'

const MAIN_PART = [
    MAIN_PART_P1,
    MAIN_PART_P2,
    MAIN_PART_P3,
    MAIN_PART_P4,
    MAIN_PART_P5,
    MAIN_PART_P6,
    MAIN_PART_P7,
]

const OUTRO_TITLE =
    'Kutsume appi vabatahtlikke kirikulaulu sõpru, et täiustada Eestikeelse Kirikulaulu Andmebaasi (E–KLAB)'

const OUTRO_P1 = 'Kallid eestikeelse kirikulaulu sõbrad,'

const OUTRO_P2 =
    'Ma püüan jätkata Eestikeelse Kirikulaulu Andmebaasi (E–KLAB) arendamist. Andmebaasis on praegu 1954 kirjet, mis pärinevad prof Toomas Siitani poolt enne 2003. aastat koostatud andmebaasist. Nüüd tahaks hakata seda andmebaasi korrastama ja sisustama informatsiooniga andmebaasis sisalduvatest väljaannetest. See töö käib mul mahuliselt üle jõu. Sestap kutsun üles vabatahtlikke kirikulauluhuvilisi osalema E–KLAB andmebaasi arendamise järgmises etapis. Meil on vaja skaneerida tuhandeid lehekülgi väljaandeid, sisestada palju teksti ja seostada see kõik kokku üheks tõhusaks andmekoguks.'

const OUTRO_P3 =
    'Kui Sa tunned valmidust selles projektis kaasa lüüa, siis palun anna endast mulle märku aadressil eerik.joks@eamt.ee'

const OUTRO_P4 =
    'Kui Sa aga arvad, et saaksid andmebaasi tulevastesse arengutesse rahaliselt panustada, siis palun tee jõukohane annetus:'

const OUTRO_P5 =
    'MTÜ Liturgilise muusika ühing Scandicus EE651010220005319016 SEB Pank'

const OUTRO_P6 =
    'Selgitusse palun lisada „E-KLAB“ ja oma isikukood tulumaksu tagastamiseks.'

const OUTRO_P7 = 'Südamlik tänu kõige abi eest'

const OUTRO_P8 = 'Eerik Jõks eerik.joks@eamt.ee'

const OUTRO = [
    OUTRO_P1,
    OUTRO_P2,
    OUTRO_P3,
    OUTRO_P4,
    OUTRO_P5,
    OUTRO_P6,
    OUTRO_P7,
    OUTRO_P8,
]

export const ABOUT = {
    TITLE,
    INTRO,
    COMMUNITY_TITLE,
    COMMUNITY,
    MAIN_PART_TITLE,
    MAIN_PART,
    OUTRO_TITLE,
    OUTRO,
}
