import { SongType } from 'types'

const BASE_URL =
    process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_PROD
        : process.env.REACT_APP_DEV

export async function getSong(id: string): Promise<SongType> {
    const song = fetch(`${BASE_URL}/song/${id}`)
        .then(response => response.json())
        .then(response => {
            const { data } = response

            return data
        })

    return song
}

export async function getSongCount(): Promise<number> {
    const count = fetch(`${BASE_URL}/song/count`)
        .then(response => response.json())
        .then(response => {
            const { data } = response

            return data
        })

    return count
}

interface GetSearchResult {
    song: SongType
    score: number
}

export interface GetSearchResults {
    results: GetSearchResult[]
}

export async function getSearch(searchTerm: string): Promise<GetSearchResults> {
    const results = fetch(`${BASE_URL}/search?term=${searchTerm}`)
        .then(response => response.json())
        .then(response => {
            const { data } = response

            return data
        })

    return results
}
