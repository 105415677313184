import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import SearchResultRow from 'components/SearchResultRow'
import { getNoResultsMessage } from 'utils/strings'
import { useAppSelector } from 'store'
import {
    activeResultIndexSelector,
    loadingSelector,
    queryDoneSelector,
    searchResultsSelector,
    searchTermSelector,
} from 'store/modules/search'

function SearchResults() {
    const searchResults = useAppSelector(searchResultsSelector)
    const activeResultIndex = useAppSelector(activeResultIndexSelector)
    const queryDone = useAppSelector(queryDoneSelector)
    const searchTerm = useAppSelector(searchTermSelector)
    const loading = useAppSelector(loadingSelector)

    const [isMouseHovering, setIsMouseHovering] = useState<boolean>(false)

    const onHover = () => {
        if (!isMouseHovering) {
            setIsMouseHovering(true)
        }
    }

    useEffect(() => {
        if (isMouseHovering) {
            setIsMouseHovering(false)
        }
    }, [activeResultIndex])

    return (
        <>
            {queryDone && searchTerm.length > 0 && !loading && (
                <>
                    {searchResults.length ? (
                        <div>
                            {searchResults.map((result, index) => {
                                return (
                                    <SearchResultRow
                                        key={`${result.title}-${index}`}
                                        result={result}
                                        active={
                                            activeResultIndex === index &&
                                            !isMouseHovering
                                        }
                                        onHover={onHover}
                                    />
                                )
                            })}
                        </div>
                    ) : (
                        <NoResultsMessage>
                            <span>{getNoResultsMessage(searchTerm)}</span>
                        </NoResultsMessage>
                    )}
                </>
            )}
        </>
    )
}

const NoResultsMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.125rem;
    padding: 16px 0;
    color: ${props => props.theme.color.purple};
`

export default SearchResults
