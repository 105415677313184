import { SongType } from 'types'

export function getSongsMainTitle(song: SongType): string | undefined {
    if (song.titleNorthEst) {
        return song.titleNorthEst
    } else if (song.titleSouthEst) {
        return song.titleSouthEst
    }

    return song.titleOriginal
}
