import React from 'react'
import styled from 'styled-components'

import SearchInput from 'components/SearchInput'
import Footer from 'components/Footer'
import { useSongCount } from 'hooks/useSongCount'
import { APP_NAME, getSongCountMessage } from 'utils/strings'

function Main() {
    const songCount = useSongCount()

    return (
        <Container>
            <Content>
                <h1>{APP_NAME}</h1>
                <span>{getSongCountMessage(songCount)}</span>
                <SearchInput />
            </Content>
            <Footer />
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20vh;

    & > h1 {
        margin-bottom: 32px;
        text-align: center;

        @media (max-width: 640px) {
            font-size: 1.5rem;
            margin-bottom: 16px;
        }
    }

    & > span {
        margin-top: 16px;
        margin-bottom: 32px;
    }

    @media (max-width: 640px) {
        height: 100vh;
    }
`

export default Main
