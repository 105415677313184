import React from 'react'
import styled from 'styled-components'

import OriginTable from '../OriginTable'
import ReferencesTable from '../ReferencesTable'
import LongInfoField from '../LongInfoField'
import { SONG_LABELS_MAP } from 'utils/constants'
import { SongType } from 'types'

interface ExtraInfoSectionProps {
    song: SongType
}

function ExtraInfoSection({ song }: ExtraInfoSectionProps) {
    return (
        <Wrapper>
            <OriginTable song={song} />
            <ReferencesTable song={song} />
            <LongInfoField
                label={SONG_LABELS_MAP.humnInfo}
                data={song.humnInfo}
            />
            <LongInfoField
                label={SONG_LABELS_MAP.bibleReferences}
                data={song.bibleReferences}
            />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    grid-area: sidePanel;
`

export default ExtraInfoSection
