import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import SearchInput from 'components/SearchInput'
import Footer from 'components/Footer'
import TitlesSection from 'components/SongView/TitlesSection'
import AuthorsSection from 'components/SongView/AuthorsSection'
import LyricsSection from 'components/SongView/LyricsSection'
import GeneralInfoTable from 'components/SongView/GeneralInfoTable'
import ExtraInfoSection from 'components/SongView/ExtraInfoSection'
import { useSong } from 'hooks/useSong'
import { APP_NAME } from 'utils/strings'

function Song() {
    const song = useSong()

    return (
        <Container>
            <Header>
                <StyledLink to={'/'}>
                    <h2>{APP_NAME}</h2>
                </StyledLink>
                <SearchInput />
            </Header>
            {song && (
                <StyledSong>
                    <TitlesSection song={song} />
                    <AuthorsSection song={song} />
                    <LyricsSection song={song} />
                    <GeneralInfoTable song={song} />
                    <ExtraInfoSection song={song} />
                </StyledSong>
            )}
            <Footer />
        </Container>
    )
}

const StyledLink = styled(Link)`
    @media (max-width: 640px) {
        text-align: center;
        margin-bottom: 16px;
    }
`

const Container = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Header = styled.div`
    position: absolute;
    top: 8px;
    display: flex;
    justify-content: center;
    width: 1200px;
    max-width: 100%;
    padding: 16px 64px;

    & > a {
        position: relative;
        top: 8px;
        text-decoration: none;
        color: black;
        margin-right: 32px;
    }

    @media (max-width: 640px) {
        padding: 8px;
        flex-direction: column;
        align-items: center;

        & > a {
            margin-right: 0;
        }
    }
`

const StyledSong = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
        'titlesSection generalInfoSection'
        'authorsSection sidePanel'
        'lyricsSection sidePanel';
    background: white;
    width: 1200px;
    max-width: 100%;
    min-height: min-content;
    border-radius: 8px;
    margin-bottom: 32px;
    margin-top: 102px;

    @media (max-width: 640px) {
        margin-top: 144px;
        grid-template-columns: 1fr;
        grid-template-areas:
            'titlesSection'
            'authorsSection'
            'generalInfoSection'
            'lyricsSection'
            'sidePanel'
            'sidePanel';
    }
`

export default Song
