import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getSearch } from 'api'

import type { AppDispatch, RootState } from 'store'
import { SearchResult } from 'types'
import { getSongsMainTitle } from 'utils/helpers'

interface SearchState {
    searchTerm: string
    results: SearchResult[]
    activeResultIndex: number
    queryDone: boolean
    loading: boolean
}

export const initialState: SearchState = {
    searchTerm: '',
    results: [],
    activeResultIndex: 0,
    queryDone: false,
    loading: false,
}

const SEARCH_STATE = 'searchState'

const searchSlice = createSlice({
    name: SEARCH_STATE,
    initialState,
    reducers: {
        setSearchTerm(state, action: PayloadAction<string>) {
            state.searchTerm = action.payload
        },
        setResults(state, action: PayloadAction<SearchResult[]>) {
            state.results = action.payload
        },
        setActiveResultIndex(state, action: PayloadAction<number>) {
            state.activeResultIndex = action.payload
        },
        setQueryDone(state, action: PayloadAction<boolean>) {
            state.queryDone = action.payload
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
    },
})

export const {
    setSearchTerm,
    setResults,
    setActiveResultIndex,
    setQueryDone,
    setLoading,
} = searchSlice.actions

export function doSearch() {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { searchTerm } = getState()[SEARCH_STATE]

        if (searchTerm.length > 1) {
            dispatch(setQueryDone(false))
            dispatch(setLoading(true))

            const { results } = await getSearch(searchTerm)

            const cleanedResults: SearchResult[] = results.map(result => {
                return {
                    UID: result.song.UID,
                    title: getSongsMainTitle(result.song),
                }
            })

            dispatch(setQueryDone(true))
            dispatch(setLoading(false))
            dispatch(setResults(cleanedResults))
        } else {
            dispatch(setResults([]))
            dispatch(setQueryDone(false))
            dispatch(setLoading(false))
        }
    }
}

export function resetSearch() {
    return async (dispatch: AppDispatch) => {
        dispatch(setSearchTerm(''))
        dispatch(setActiveResultIndex(0))
    }
}

export const searchTermSelector = (state: RootState) =>
    state[SEARCH_STATE].searchTerm
export const searchResultsSelector = (state: RootState) =>
    state[SEARCH_STATE].results
export const activeResultIndexSelector = (state: RootState) =>
    state[SEARCH_STATE].activeResultIndex
export const queryDoneSelector = (state: RootState) =>
    state[SEARCH_STATE].queryDone
export const loadingSelector = (state: RootState) => state[SEARCH_STATE].loading

export default searchSlice.reducer
