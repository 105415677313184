import React from 'react'
import styled from 'styled-components'

import Table from 'components/Table'
import { SONG_LABELS_MAP, TABLE_TITLES } from 'utils/constants'
import { SongType } from 'types'

interface GeneralInfoTableProps {
    song: SongType
}

function GeneralInfoTable({ song }: GeneralInfoTableProps) {
    const rows = [
        { label: SONG_LABELS_MAP.year, data: song.year || '-' },
        { label: SONG_LABELS_MAP.strroof, data: song.strroof || '-' },
        { label: SONG_LABELS_MAP.rhythm, data: song.rhythm || '-' },
        { label: SONG_LABELS_MAP.melodyInfo, data: song.melodyInfo || '-' },
    ]

    return <Wrapper><Table title={TABLE_TITLES.generalInfo} rows={rows} /></Wrapper>
}

const Wrapper = styled.div`
    padding: 32px 0 32px 40px;
    border-bottom: 1px solid;
    border-color: ${props => props.theme.color.purple};
`

export default GeneralInfoTable
