export const KEYS = {
    ESCAPE: 'Escape',
    ARROW_DOWN: 'ArrowDown',
    ARROW_UP: 'ArrowUp',
    ENTER: 'Enter',
    TAB: 'Tab',
}

export const BOOK_REFS_MAP: { [key: string]: string } = {
    LR1721: 'LR1721',
    LR1848: 'LR1848',
    LR1899: 'LR1899',
    LR1991: 'KLPR1991',
    Punschel: 'Punschel',
    Hagen1: 'Hagen I',
    Hagen2: 'Hagen II',
    Hagen3: 'Hagen III',
    KreegiRKVfond: 'Kreegi RKV fond',
    UKLPR: 'UKLPR',
}

export const SONG_LABELS_MAP: { [key: string]: string } = {
    titleNorthEst: 'Pealkiri Põhja-Eesti',
    titleSouthEst: 'Pealkiri Lõuna-Eesti',
    titleOriginal: 'Pealkiri originaal',
    textAuthor: 'Teksti autor',
    melodyAuthor: 'Meloodia autor',
    translationAuthor: 'Tõlketeksti autor',
    originalText: 'Sõnad',
    translationText: 'Eestikeelne tõlge',
    textOrigin: 'Tekst',
    melodyOrigin: 'Meloodia',
    translationTextOrigin: 'Tõlketekst',
    year: 'Aasta',
    strroof: 'Stroof',
    rhythm: 'Rütm',
    melodyInfo: 'Meloodiline info',
    humnInfo: 'Hümnoloogiline info',
    bibleReferences: 'Bibliograafiline info',
    ...BOOK_REFS_MAP,
}

export const TABLE_TITLES = {
    generalInfo: 'Üldinfo',
    origin: 'Päritolu',
    references: 'Viited',
}
