import React from 'react'
import styled from 'styled-components'

import TitleField from 'components/SongView/TitleField'
import { SONG_LABELS_MAP } from 'utils/constants'
import { SongType } from 'types'

interface TitlesSectionProps {
    song: SongType
}

function TitlesSection({ song }: TitlesSectionProps) {
    return (
        <Wrapper>
            <TitleField
                label={SONG_LABELS_MAP.titleOriginal}
                data={song.titleOriginal || '-'}
            />
            <TitleField
                label={SONG_LABELS_MAP.titleNorthEst}
                data={song.titleNorthEst || '-'}
            />
            <TitleField
                label={SONG_LABELS_MAP.titleSouthEst}
                data={song.titleSouthEst || '-'}
            />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    grid-area: titlesSection;
    padding: 32px 0 32px 40px;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-color: ${props => props.theme.color.purple};

    @media (max-width: 640px) {
        border-right: unset;
    }
`

export default TitlesSection
