import React, { useEffect } from 'react'
import styled from 'styled-components'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SpinnerCircular } from 'spinners-react'

import SearchResults from 'components/SearchResults'
import { useDebounce } from 'hooks/useDebounce'
import { useKeyboard } from 'hooks/useKeyboard'
import { useAppDispatch, useAppSelector } from 'store'
import {
    doSearch,
    loadingSelector,
    queryDoneSelector,
    searchTermSelector,
    setSearchTerm,
} from 'store/modules/search'

function SearchInput() {
    const dispatch = useAppDispatch()

    const searchTerm = useAppSelector(searchTermSelector)
    const queryDone = useAppSelector(queryDoneSelector)
    const loading = useAppSelector(loadingSelector)

    const [debouncedSearchTerm] = useDebounce(searchTerm, 350)
    const onKeyDown = useKeyboard()

    useEffect(() => {
        dispatch(doSearch())
    }, [debouncedSearchTerm])

    return (
        <StyledSearchInput
            showResults={queryDone && searchTerm.length > 0}
            onKeyDown={onKeyDown}
        >
            <div>
                {loading ? (
                    <Spinner
                        enabled={loading}
                        size={20}
                        thickness={200}
                        color={'#7c7c7c'}
                        secondaryColor={'#cdcdcd'}
                    />
                ) : (
                    <Icon icon={faSearch} />
                )}
                <Input
                    onChange={e => dispatch(setSearchTerm(e.target.value))}
                    placeholder="Sisesta pealkiri, autor, laulusõnad..."
                    value={searchTerm}
                />
                <ClickableIcon
                    icon={faTimes}
                    onClick={() => dispatch(setSearchTerm(''))}
                    hideicon={searchTerm.length === 0 ? 'true' : 'false'}
                />
            </div>
            <SearchResults />
        </StyledSearchInput>
    )
}

interface StyledSearchInputProps {
    showResults: boolean
}

const StyledSearchInput = styled.div<StyledSearchInputProps>`
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 90vw;
    max-width: 600px;
    max-height: 560px;
    height: min-content;
    padding-bottom: ${props => (props.showResults ? '8px' : '')};
    border-radius: ${props => (props.showResults ? '16px' : '64px')};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    overflow: ${props => (props.showResults ? 'auto' : '')};

    & > div:first-child {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        border-radius: 64px;
        width: 100%;
    }
`

const Input = styled.input`
    color: rgb(34, 34, 34);
    font-size: 1.125rem;
    width: 100%;
    padding: 12px 0px;
    outline: none;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const Icon = styled(FontAwesomeIcon)`
    font-size: 1.25rem;
    color: rgb(60, 60, 60);
    margin: 16px;
`

interface ClickableIconProps {
    hideicon: string
}

const ClickableIcon = styled(Icon)<ClickableIconProps>`
    cursor: ${props => (props.hideicon === 'true' ? 'auto' : 'pointer')};
    opacity: ${props => (props.hideicon === 'true' ? '0' : '')};
`

const Spinner = styled(SpinnerCircular)`
    margin: 16px;
`

export default SearchInput
