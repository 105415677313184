import React from 'react'
import styled from 'styled-components'

import LongInfoField from '../LongInfoField'
import { SONG_LABELS_MAP } from 'utils/constants'
import { SongType } from 'types'
import { NO_DATA } from 'utils/strings'

interface LyricsSectionProps {
    song: SongType
}

function AuthorsSection({ song }: LyricsSectionProps) {
    return (
        <Wrapper>
            <LongInfoField
                label={SONG_LABELS_MAP.originalText}
                data={song.originalText || NO_DATA}
            />
            <LongInfoField
                label={SONG_LABELS_MAP.translationText}
                data={song.translationText || NO_DATA}
            />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    grid-area: lyricsSection;
    padding: 32px 0 32px 40px;
    border-right: 1px solid;
    border-color: ${props => props.theme.color.purple};

    & > div:first-child {
        margin-right: 64px;
    }

    @media (max-width: 640px) {
        border-right: unset;
        border-bottom: 1px solid;
        border-color: ${props => props.theme.color.purple};
    }
`

export default AuthorsSection
