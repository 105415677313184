import { useEffect, useState } from 'react'

import { getSongCount } from 'api'

export function useSongCount(): number {
    const [songCount, setSongCount] = useState<number>(1900)

    useEffect(() => {
        async function fetchSongCount() {
            const count = await getSongCount()

            setSongCount(count)
        }

        fetchSongCount()
    }, [])

    return songCount
}
