import React from 'react'
import styled from 'styled-components'

import AuthorField from 'components/SongView/AuthorField'
import { SONG_LABELS_MAP } from 'utils/constants'
import { SongType } from 'types'

interface AuthorsSectionProps {
    song: SongType
}

function AuthorsSection({ song }: AuthorsSectionProps) {
    return (
        <Wrapper>
            <AuthorField
                label={SONG_LABELS_MAP.melodyAuthor}
                firstName={song.melodyAuthorFirstName}
                lastName={song.melodyAuthorLastName}
                birthDate={song.melodyAuthorBirthDate}
                deathDate={song.melodyAuthorDeathDate}
            />
            <AuthorField
                label={SONG_LABELS_MAP.textAuthor}
                firstName={song.textAuthorFirstName}
                lastName={song.textAuthorLastName}
                birthDate={song.textAuthorBirthDate}
                deathDate={song.textAuthorDeathDate}
            />
            <AuthorField
                label={SONG_LABELS_MAP.translationAuthor}
                firstName={song.translationAuthorFirstName}
                lastName={song.translationAuthorLastName}
                birthDate={song.translationAuthorBirthDate}
                deathDate={song.translationAuthorDeathDate}
            />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    grid-area: authorsSection;
    padding: 32px 0 32px 40px;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-color: ${props => props.theme.color.purple};

    @media (max-width: 640px) {
        border-right: unset;
    }
`

export default AuthorsSection
