import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { KEYS } from 'utils/constants'
import { useAppDispatch, useAppSelector } from 'store'
import {
    activeResultIndexSelector,
    resetSearch,
    searchResultsSelector,
    setActiveResultIndex,
} from 'store/modules/search'

const { ARROW_DOWN, ARROW_UP, ENTER } = KEYS

export function useKeyboard() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const activeResultIndex = useAppSelector(activeResultIndexSelector)
    const searchResults = useAppSelector(searchResultsSelector)

    const onKeyDown = useCallback(
        e => {
            if (e.key === ARROW_UP) {
                e.preventDefault()

                if (activeResultIndex >= 1) {
                    dispatch(setActiveResultIndex(activeResultIndex - 1))
                }
            }

            if (e.key === ARROW_DOWN) {
                e.preventDefault()

                if (searchResults.length - 1 > activeResultIndex) {
                    dispatch(setActiveResultIndex(activeResultIndex + 1))
                }
            }

            if (e.key === ENTER) {
                e.preventDefault()

                dispatch(resetSearch())

                navigate(`/song/${searchResults[activeResultIndex].UID}`)
            }
        },
        [activeResultIndex, searchResults],
    )

    return onKeyDown
}
