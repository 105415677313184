import React from 'react'
import styled from 'styled-components';

interface AuthorDatesProps {
    birthDate?: Date
    deathDate?: Date
}

function formatDate(date: Date) {
    return date.toISOString().split('T')[0]
}

function AuthorDates({ birthDate, deathDate }: AuthorDatesProps) {
    if (!birthDate && !deathDate) {
        return null
    }

    return (
        <span>
            ({birthDate && formatDate(birthDate)}
            {' - '}
            {deathDate && formatDate(deathDate)})
        </span>
    )
}

interface AuthorFieldProps {
    label: string
    firstName?: string
    lastName?: string
    birthDate?: Date
    deathDate?: Date
}

function AuthorField({
    label,
    firstName,
    lastName,
    birthDate,
    deathDate,
}: AuthorFieldProps) {
    const hasNoData = !firstName && !lastName && !birthDate && !deathDate;

    return (
        <Wrapper>
            <Label>{label}</Label>
            {!hasNoData ? (
                <div>
                    <AuthorName>
                        {firstName} {lastName}
                    </AuthorName>
                    <AuthorDates birthDate={birthDate} deathDate={deathDate} />
                </div>
            ) : (
                <span>-</span>
            )}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1.125rem;
    padding: 8px 0;
`

const Label = styled.span`
    color: ${props => props.theme.color.purple};
    padding-bottom: 4px;
`

const AuthorName = styled.span`
    margin-right: 8px
`

export default AuthorField
