import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { getSong } from 'api'
import { SongType } from 'types'

export function useSong(): SongType | undefined {
    const { pathname } = useLocation()
    const [song, setSong] = useState<SongType>()

    useEffect(() => {
        async function fetchSong() {
            const id = pathname.split('/')[2]
            const song = await getSong(id)

            setSong(song)
        }

        fetchSong()
    }, [pathname])

    return song
}
