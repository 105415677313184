import React from 'react'
import styled from 'styled-components'

interface TitleFieldProps {
    label: string
    data: string
}

function TitleField({ label, data }: TitleFieldProps) {
    return (
        <Wrapper>
            <Label>{label}</Label>
            <span>{data}</span>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1.125rem;
    padding: 8px 0;

    @media (max-width: 640px) {
        font-size: 1rem;
    }
`

const Label = styled.span`
    color: ${props => props.theme.color.purple};
    padding-bottom: 4px;
`

export default TitleField
