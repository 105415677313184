import React from 'react'
import styled from 'styled-components'
import { NO_DATA } from 'utils/strings'

interface Row {
    label: string
    data: string
}

interface TableProps {
    title: string
    rows: (Row | undefined)[]
}

function Table({ title, rows }: TableProps) {
    return (
        <Wrapper>
            <Title>{title}</Title>
            {rows.length > 0 ? (
                <StyledTable>
                    <Labels>
                        {rows.map((row, index) => {
                            if (!row) {
                                return null
                            }

                            return (
                                <span key={`${row.label}-${index}`}>
                                    {row.label}
                                </span>
                            )
                        })}
                    </Labels>
                    <Data>
                        {rows.map((row, index) => {
                            if (!row) {
                                return null
                            }

                            return (
                                <span key={`${row.label}-${index}`}>
                                    {row.data}
                                </span>
                            )
                        })}
                    </Data>
                </StyledTable>
            ) : (
                <span>{NO_DATA}</span>
            )}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 0;
`

const Title = styled.span`
    color: ${props => props.theme.color.purple};
    font-size: 1.125rem;
    margin-bottom: 16px;
`

const StyledTable = styled.div`
    display: flex;
`

const Labels = styled.div`
    display: flex;
    flex-direction: column;
    width: 140px;
    font-weight: 200;

    & > span {
        padding: 4px 0;
    }
`

const Data = styled.div`
    display: flex;
    flex-direction: column;
    width: 140px;

    & > span {
        padding: 4px 0;
    }
`

export default Table
