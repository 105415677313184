export const APP_NAME = "Kirikulaulude andmebaas";

export const CONTACTS = {
    EMAIL: "lauluraamat@gmail.com",
    PHONE: "59 123 123",
  };
  
export const ABOUT_LINK_NAME = "Loe lähemalt";

export const NO_DATA = "andmed puuduvad";

export const getNoResultsMessage = (term: string) =>
    `Vastet terminile "${term}" ei leitud`

export const getSongCountMessage = (count: number) => `Otsi ${count} laulu hulgast`
