import React from "react";
import {
  faEnvelope,
  faPhone,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ABOUT_LINK_NAME, APP_NAME, CONTACTS } from 'utils/strings'

function Footer() {
    return (
        <StyledFooter>
            <Column gridArea="contactInfo">
                <div>
                    <Icon icon={faEnvelope} />
                    <span>{CONTACTS.EMAIL}</span>
                </div>
                <div>
                    <Icon icon={faPhone} />
                    <span>{CONTACTS.PHONE}</span>
                </div>
            </Column>
            <Column gridArea="appName">
                <AppName>{APP_NAME}</AppName>
            </Column>
            <Column gridArea="aboutInfo">
                <div>
                    <Icon icon={faInfoCircle} />
                    <AboutLink to={'/about'}>{ABOUT_LINK_NAME}</AboutLink>
                </div>
            </Column>
        </StyledFooter>
    )
}

const StyledFooter = styled.footer`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'contactInfo appName aboutInfo';
    background: #2d2d2d;
    width: 100%;
    color: white;
    margin-top: auto;
    padding: 8px 32px;

    @media (max-width: 640px) {
        grid-template-areas:
            'appName'
            'contactInfo'
            'aboutInfo';
        grid-template-columns: 1fr;
        flex-direction: column;
        align-items: center;
    }
`

interface ColumnProps {
    gridArea: string
}

const Column = styled.div<ColumnProps>`
    grid-area: ${props => props.gridArea};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${props => (props.gridArea === 'aboutInfo' ? 'flex-end' : '')};
    padding: 8px 0;

    @media (max-width: 640px) {
        justify-content: center;
        align-items: center;
    }

    & div {
        margin-bottom: 4px;
    }
`

const AppName = styled.span`
    font-weight: 800;
    font-size: 1.125rem;
    text-align: center;
`

const AboutLink = styled(Link)`
    color: white;
`

const Icon = styled(FontAwesomeIcon)`
    margin-right: 8px;
`

export default Footer;
