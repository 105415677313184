import React, { useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { ABOUT } from 'utils/aboutInfo'

function renderParagraphs(paragraphs: string[]) {
    return paragraphs.map(paragraph => (
        <p key={paragraph.substring(0, 10)}>{paragraph}</p>
    ))
}

function About() {
    const chapterOneRef = useRef(null)
    const chapterTwoRef = useRef(null)
    const chapterThreeRef = useRef(null)

    const executeScroll = (ref: React.RefObject<HTMLInputElement>) =>
        ref?.current?.scrollIntoView()

    return (
        <Container>
            <BackLink to={'/'}>Tagasi avalehele</BackLink>
            <TextContainer>
                <Wrapper>
                    <h1>{ABOUT.TITLE}</h1>
                    <Refs>
                        <a onClick={() => executeScroll(chapterOneRef)}>
                            Kogukond
                        </a>
                        <a onClick={() => executeScroll(chapterTwoRef)}>
                            E–KLABist ja kirikulaulust pisut pikemalt
                        </a>
                        <a onClick={() => executeScroll(chapterThreeRef)}>
                            Tule appi E–KLABi täiendama
                        </a>
                    </Refs>
                    {renderParagraphs(ABOUT.INTRO)}
                    <h3 ref={chapterOneRef}>{ABOUT.COMMUNITY_TITLE}</h3>
                    {renderParagraphs(ABOUT.COMMUNITY)}
                    <h3 ref={chapterTwoRef}>{ABOUT.MAIN_PART_TITLE}</h3>
                    {renderParagraphs(ABOUT.MAIN_PART)}
                    <h3 ref={chapterThreeRef}>{ABOUT.OUTRO_TITLE}</h3>
                    {renderParagraphs(ABOUT.OUTRO)}
                </Wrapper>
            </TextContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const BackLink = styled(Link)`
    margin: 16px 0;
    color: black;
    font-size: 1.125rem;
    width: 800px;
    max-width: calc(100vw - 32px);
    margin-left: 32px;
`

const TextContainer = styled.div`
    background-color: white;
    width: 800px;
    max-width: 100vw;
    border-radius: 4px;
    padding: 32px 16px;
`

const Refs = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    & > a {
        text-decoration: underline;
        cursor: pointer;
        margin: 4px 0;
    }
`

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr min(65ch, 100%) 1fr;

    & > * {
        grid-column: 2;
    }

    & > h1 {
        text-align: center;
        margin-bottom: 32px;
    }

    & > h3 {
        margin-top: 32px;
        margin-bottom: 16px;
        font-weight: 400;
        color: #4100ff;
        font-size: 1.25rem;
    }

    & > p {
        font-size: calc(1.1875rem);
        margin-bottom: 32px;
    }
`

export default About
